import React, { useState, useEffect } from "react"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import axios from "axios"
import * as Sentry from "@sentry/gatsby"
import "./Form.css"

//eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default function FormScreen({
  content,
  step,
  length,
  isFirst,
  isLast,
  onPressPrev,
  onPressNext,
}) {
  const [error, setError] = useState(false)
  const [showThank, toggleThankScreen] = useState(false)
  const [showTextField, toggleTextField] = useState(false)
  const [choices, setChoices] = useState([])
  const [email, setEmail] = useState("")
  const [storeName, setStoreName] = useState("")
  const [bizNature, setBizNature] = useState("")
  const isComplete = choices.length >= length - 2
  const isEmail = emailRegex.test(email)

  useEffect(() => {
    toggleTextField(false)
  }, [step])

  const isSelected = (optionVal) => choices.find((c) => c.value === optionVal)

  const getAnsById = (id) => {
    const ans = choices.find((c) => c.questionId === id)
    if (ans) {
      return ans.value
    }
    return ""
  }

  const getPlatformResult = (budget) => {
    switch (budget) {
      case "HK$ 20,000-50,000":
        return "Shopify"
      case "HK$ 50,001-100,000":
        return "Wordpress"
      case "HK$ 100,001-200,000":
        return "Sylius"
      case "Above HK$ 200,000":
        return "Magento"
      default:
        return ""
    }
  }

  const onFillForm = (questionId, val) => {
    const prevAns = getAnsById(content.order)
    if (prevAns && prevAns.value === val) {
      return
    }

    const newChoices = choices.filter((c) => c.questionId !== questionId)
    setChoices([
      ...newChoices,
      {
        questionId: questionId,
        value: val,
      },
    ])
  }

  const onToggleCheckbox = (questionId, val) => {
    const prevAns = choices.find((c) => c.value === val)

    if (prevAns) {
      const newChoices = choices.filter((c) => c.value !== val)

      setChoices(newChoices)
    } else {
      setChoices([
        ...choices,
        {
          questionId: questionId,
          value: val,
        },
      ])
    }
  }

  const postQuizResult = async () => {
    try {
      const body = {
        onlineStore: getAnsById(1) === "No" ? "No" : storeName,
        bizNature: getAnsById(2) === "Others" ? bizNature : getAnsById(2),
        features: choices
          .filter((c) => c.questionId === 3)
          .map((f) => f.value)
          .join(", "),
        budget: getAnsById(4),
        platform: getPlatformResult(getAnsById(4)),
        email: email,
      }

      const headers = {
        "Content-Type": "application/json",
      }
      const response = await axios.post(
        `${process.env.GATSBY_SERVER_API}/quizzes`,
        body,
        {
          headers,
        }
      )
      if (!response.data) {
        setError(true)
        Sentry.captureMessage("No response from MailerLite")
      }
    } catch (error) {
      console.log(error)
      Sentry.captureException(error)
      setError(true)
    } finally {
      toggleThankScreen(true)
    }
  }

  const submitForm = () => {
    Sentry.captureMessage("Press quiz submit")
    if (isComplete && isEmail) {
      postQuizResult()
    } else {
      alert("🖋 Please fill in form")
    }
  }

  if (showThank) {
    return (
      <section id="quiz-form">
        <div className="quiz-question">
          <div className="row">
            <div className="col text-center mb-3">
              <h1>{error ? "Something went wrong" : "Thank you!"}</h1>
            </div>
          </div>

          <div className="separator" />
          <p>
            {error
              ? "Please try again"
              : `The quiz result will be sent to ${email}🔥`}
          </p>
        </div>
      </section>
    )
  }
  return (
    <section id="quiz-form">
      <div className="quiz-question">
        <div className="row">
          <div className="col text-center mb-3">
            <h1 id="question-title">{content.title}</h1>
          </div>
        </div>

        <div className="separator" />
        {(content.type === "mc" || content.type === "checkbox") && (
          <div className="options-container">
            {content.options.map((option) => (
              <button
                key={option.id}
                className={`mc-option ${
                  isSelected(option.value) ? "mc-option-selected" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault()

                  if (content.type === "checkbox") {
                    onToggleCheckbox(content.order, option.value)
                  } else {
                    onFillForm(content.order, option.value)

                    if (option.withInput) {
                      toggleTextField(true)
                    } else {
                      // next question
                      setTimeout(() => {
                        onPressNext()
                      }, 1200)
                    }
                  }
                }}
              >
                <p>{option.value}</p>
              </button>
            ))}
          </div>
        )}

        {showTextField && step === 1 && (
          <textarea
            rows="2"
            placeholder="Name"
            value={storeName}
            onChange={(e) => setStoreName(e.target.value)}
          />
        )}
        {showTextField && step === 2 && (
          <textarea
            rows="3"
            placeholder="Business nature"
            value={bizNature}
            onChange={(e) => setBizNature(e.target.value)}
          />
        )}
        {content.type === "input" && (
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="emailAddress"
            aria-describedby="emailHelp"
            required
          />
        )}
      </div>

      <div className="nav-row">
        {step === 1 ? (
          <button
            type="button"
            onClick={onPressNext}
            className="btn default-btn text-uppercase btn-round"
          >
            {isLast ? (
              "Submit"
            ) : (
              <FaArrowRight color={"var(--white)"} size={18} />
            )}
          </button>
        ) : (
          <div className="nav-row">
            <button
              type="button"
              onClick={onPressPrev}
              className="btn default-btn text-uppercase btn-round"
            >
              <FaArrowLeft color={"var(--white)"} size={18} />
            </button>

            <p>
              {step} / {length - 1}
            </p>

            <button
              type="button"
              disabled={!isComplete && isEmail && isLast}
              onClick={() => (isLast ? submitForm() : onPressNext())}
              className="btn default-btn text-uppercase btn-round"
            >
              {isLast ? (
                "Submit"
              ) : (
                <FaArrowRight color={"var(--white)"} size={18} />
              )}
            </button>
          </div>
        )}
      </div>
    </section>
  )
}
