import React, { useState } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import FormScreen from "../components/Quiz/Form"
import { CONTENT } from "../components/Quiz/content"

const QuizPage = ({ data }) => {
  const [step, setStep] = useState(1)

  const getContent = () => CONTENT.find((c) => c.order === step)
  const isLast = step === CONTENT.length
  const isFirst = step === 1

  const onPressNext = () => {
    setStep(step + 1)

    if (process.env.NODE_ENV === 'production') {
      window.dataLayer.push({ 'event': `Quiz_P${step}_Next_Click` });
    }
  }

    return (
      <>
        <SEO title="eCommerce Quiz" keywords={[`eCommerce`, `quiz`]} />
        <FormScreen
          step={step}
          length={CONTENT.length}
          isLast={isLast}
          isFirst={isFirst}
          content={getContent()}
          onPressPrev={() => (step === 1 ? null : setStep(step - 1))}
          onPressNext={() => (isLast ? null : onPressNext())}
        />
      </>
    )
}

export const query = graphql`
  {
    img: file(relativePath: { eq: "quiz-bg.jpeg" }) {
      childImageSharp {
        fluid(
          maxWidth: 1920
          quality: 100
        ) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default QuizPage
