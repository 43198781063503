export const CONTENT = [
  {
    order: 1,
    type: "mc",
    title: "Do you own an online store now?",
    options: [
      {
        id: "1",
        value: "Yes",
        withInput: true,
      },
      {
        id: "2",
        value: "No",
      },
    ],
  },
  {
    order: 2,
    type: "mc",
    title: "What is the business nature of your company? ",
    options: [
      {
        id: "1",
        value: "Retail",
      },
      {
        id: "2",
        value: "Wholesale",
      },
      {
        id: "3",
        value: "Healthcare",
      },
      {
        id: "4",
        value: "Logistics",
      },
      {
        id: "5",
        value: "Food & Beverage",
      },
      {
        id: "6",
        value: "Hospitality",
      },
      {
        id: "7",
        value: "Finance",
      },
      {
        id: "8",
        value: "Properties",
      },
      {
        id: "9",
        value: "Technology",
      },
      {
        id: "10",
        value: "Others",
        withInput: true,
      },
    ],
  },
  {
    order: 3,
    type: "checkbox",
    title: "What eCommerce features do you expect?",
    options: [
      {
        id: "1",
        value: "Membership system",
      },
      {
        id: "2",
        value: "Payment options",
      },
      {
        id: "3",
        value: "Shopping cart",
      },
      {
        id: "4",
        value: "Checkout",
      },
      {
        id: "5",
        value: "SEO friendly",
      },
      {
        id: "6",
        value: "Analytics tool",
      },
      {
        id: "7",
        value: "Promotion and discount code",
      },
      {
        id: "8",
        value: "Customer relationship management",
      },
    ],
  },

  {
    order: 4,
    type: "mc",
    title: `What is your budget?`,
    options: [
      {
        id: "1",
        value: "HK$ 20,000-50,000",
      },
      {
        id: "2",
        value: "HK$ 50,001-100,000",
      },
      {
        id: "3",
        value: "HK$ 100,001-200,000",
      },
      {
        id: "4",
        value: "Above HK$ 200,000",
      },
    ],
  },
  {
    order: 5,
    type: "input",
    title: `Please leave your email address below for the quiz results`,
  },
]
